// /** @type {import('twind').Configuration} */

export default {
    theme: {
        fontFamily: {
            sans: ['Inter', 'Calibre', 'sans-serif'],
            serif: ['Merriweather', 'Georgia', 'serif'],
            body: ['Inter', 'Calibre', 'sans-serif']
        },
        colors: {
            transparent: 'transparent',
            current: 'currentColor',
            white: '#ffffff',
            black: '#000000',
            red: {
                200: '#fecaca'
            },
            orange: {
                200: '#fed7aa'
            },
            yellow: {
                200: '#fef08a'
            },
            lime: {
                200: '#d9f99d'
            },
            violet: {
                200: '#ddd6fe'
            },
            fuchsia: {
                200: '#f5d0fe'
            },
            blue: {
                50: '#E9F1FF',
                200: '#BFD4FE',
                400: '#87ADFE',
                500: '#4f77ff',
                600: '#335DEC',
                700: '#1F49D8'
            },
            sky: {
                200: '#BAE6FD',
                700: '#0369A1'
            },
            // gray values are based on tailwindcss's stone palette
            // 150 is a custom value
            gray: {
                50: '#fafaf9',
                100: '#f5f5f4',
                150: '#eeedec',
                200: '#e7e5e4',
                300: '#d6d3d1',
                400: '#a8a29e',
                500: '#78716c',
                600: '#57534e',
                700: '#44403c',
                800: '#292524',
                900: '#1c1917',
                950: '#0c0a09'
            },
            cyan: {
                200: '#a5f3fc',
                500: '#5CC2D4'
            },
            rose: {
                50: '#FFF1F2',
                200: '#FECDD3',
                300: '#FDA4AF',
                400: '#FB7185',
                500: '#F43F5E',
                700: '#BE123C',
                800: '#8d122d'
            },
            amber: {
                50: '#FFFBEB',
                100: '#FEF1C3',
                200: '#FDE68A',
                300: '#FCD34D',
                400: '#FBBF24',
                500: '#F59E0B',
                700: '#B45309'
            },
            pink: {
                200: '#fbcfe8',
                300: '#F9A8D4',
                500: '#E64C8E'
            },
            purple: {
                500: '#b05ce4'
            },
            teal: {
                400: '#2DD4BF',
                500: '#28B2AD',
                800: '#0D9488'
            },
            indigo: {
                50: '#EEF2FF',
                200: '#C7D2FE'
            },
            emerald: {
                50: '#ECFDF5',
                200: '#A7F3D0',
                500: '#10B981',
                600: '#059669',
                700: '#00784B',
                800: '#25614E'
            }
        },
        extend: {
            screens: {
                xs: '450px',
                md: '768px',
                standalone: { raw: '(display-mode:standalone)' }
            },
            fontSize: {
                '2xs': ['0.65rem'],
                xsm: ['0.8125rem']
            },
            boxShadow: {
                'inner-2': 'inset 0 3px 5px rgb(0 0 0 / 13%)'
            },
            // For vh, "max-h-[80vh]" doesn't work so
            // we have to add custom heights here
            maxHeight: {
                '34vh': '34vh',
                '80vh': '80vh'
            },
            cursor: {
                grab: 'grab'
            },
            transitionProperty: {
                width: 'width'
            }
        }
    }
};
